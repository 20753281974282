html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Rubik', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  /* font-family: rubik */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (min-width: 576px) { 

}

@media (min-width: 768px) { 

}

@media (min-width: 992px) { 

}

@media (min-width: 1200px) { 

}

@media (min-width: 1400px) {

}

@media (min-width: 1660px) {
  h1 {
    font-size: 5rem;
  }
  
  h2 {
    font-size: 4rem;
  }
}

@media (min-width: 1920px) {
  h1 {
    font-size: 7rem;
  }
  
  h2 {
    font-size: 5rem;
  }

  .btn-big {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    border-radius: 0.3rem;
  }
}

#root {
  width: 100%;
  height: 100%;
}

:root {
  --primary: rgb(1, 86, 255);
  --secondary: #333333;
  --error: #ce0606;
  --success: #00904a;
  --white: #ffffff;
  --black: #000000;
  --lightgrey: #f2f4f7;
  --auburn: #b11226;
  --red: #df3e2e;
  --orange: #e87634;
  --yellow: #ffb059;
  --green: #008400;
  --cyan: #6dedd9;
  --blue: #4e5de4;
  --main-gradient: linear-gradient(135deg, rgba(0, 255, 237, 1) 0%, rgba(85, 1, 255, 1) 50%, rgba(255, 0, 204, 1) 100%);
}