.land-foot-link,
.land-foot-link:hover {
  color: #c2c3c4;
  text-decoration-line: none;
}

.land-foot-copyright {
  color: #c2c3c4;
}

.text-left {
  text-align: start;
}

.footer-section {
  padding-top: 0.5rem !important;
}

.footer-form {
  width: 360px;
}